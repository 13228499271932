import {Injectable} from '@angular/core';

interface AlertOptions {
  id?: string;
  type?: 'primary' | 'danger';
  message?: string;
  hiding?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private _alerts: AlertOptions[] = [];

  get alerts(): AlertOptions[] {
    return this._alerts;
  }

  show(options: AlertOptions = {}): void {
    options.id = Math.random().toString();

    if (!options.type) {
      options.type = 'primary';
    }

    if (!options.message) {
      options.message = 'Изменения успешно сохранены.';
    }

    this._alerts.push(options);

    setTimeout(() => this.hide(options.id), 3000);
  }

  hide(id: string): void {
    const index = this.alerts.findIndex(a => a.id === id);
    if (index === -1 || this._alerts[index].hiding) {
      return;
    }

    this._alerts[index].hiding = true;
    setTimeout(() => this.remove(id), 250);
  }

  remove(id: string): void {
    const index = this.alerts.findIndex(a => a.id === id);
    if (index !== -1) {
      this._alerts.splice(index, 1);
    }
  }
}
